angular
  .module('atelier.userAccounts')
  .component('unlock', {
    controller: Controller,
    template:   require('./unlock.template.pug')(),
    bindings:   {
      user: '<'
    }
  })

Controller['$inject'] = ['$http', '$mdDialog', 'dialogComponent']
function Controller ($http, $mdDialog, dialogComponent) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit    = onInit
  ctrl.cancel     = cancel
  ctrl.unlock     = unlock
  ctrl.updateUser = updateUser

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
  }

  // Public functions
  // ---------------------------------------------------------------------------
  function cancel () {
    $mdDialog.cancel()
  }

  function unlock () {
    $http
      .post('/api/users/' + ctrl.user.id + '/unlock')
      .then($mdDialog.hide)
  }

  function updateUser (event) {
    dialogComponent.show({
      template:    '<edit-user user-id="$dialog.userId"></edit-user>',
      ariaLabel:   'Editer un utilisateur',
      targetEvent: event,
      multiple:    true,
      locals:      {
        userId: ctrl.user.id
      }
    }).then($mdDialog.hide)
  }
}
