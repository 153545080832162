angular
  .module('atelier.userAccounts')
  .component('userAccountStatus', {
    controller: Controller,
    template:   require('./user_account_status.template.pug')(),
    bindings:   {
      user:      '<',
      displayAs: '@'
    },
    require: {
      usersDashboardCtrl: '?^^usersDashboard'
    }
  })

Controller['$inject'] = ['dialogComponent']
function Controller (dialogComponent) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit     = onInit
  ctrl.unlock      = unlock
  ctrl.deactivate  = deactivate
  ctrl.reactivate  = reactivate

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
  }

  // Public functions
  // ---------------------------------------------------------------------------

  function unlock (user, event) {
    dialogComponent.show({
      template:    '<unlock user="$dialog.user">',
      ariaLabel:   'Déverrouiller le compte de ' + user.name,
      targetEvent: event,
      locals:      {
        user: user
      }
    }).then(reload)
  }

  function deactivate (user, event) {
    dialogComponent.show({
      template:    '<deactivate user="$dialog.user">',
      ariaLabel:   'Désactiver le compte de ' + user.name,
      targetEvent: event,
      locals:      {
        user: user
      }
    }).then(reload)
  }

  function reactivate (user, event) {
    dialogComponent.show({
      template:    '<reactivate user="$dialog.user">',
      ariaLabel:   'Réactiver le compte de ' + user.name,
      targetEvent: event,
      locals:      {
        user: user
      }
    }).then(reload)
  }

  // Private functions
  // ---------------------------------------------------------------------------
  function reload () {
    ctrl.usersDashboardCtrl.users.reload()
  }
}
