angular
  .module('atelier.userAccounts')
  .component('reactivate', {
    controller: Controller,
    template:   require('./reactivate.template.pug')(),
    bindings:   {
      user: '<'
    }
  })

Controller['$inject'] = ['$http', '$mdDialog', 'dialogComponent']
function Controller ($http, $mdDialog, dialogComponent) {
  const ctrl = this

  // Bindable members
  // ---------------------------------------------------------------------------
  ctrl.$onInit    = onInit
  ctrl.cancel     = cancel
  ctrl.reactivate = reactivate

  // Component hooks
  // ---------------------------------------------------------------------------
  function onInit () {
  }

  // Public functions
  // ---------------------------------------------------------------------------
  function cancel () {
    $mdDialog.cancel()
  }

  function reactivate () {
    $http
      .post('/api/users/' + ctrl.user.id + '/reactivate')
      .then($mdDialog.hide)
  }
}
